const CLIENT_LIST = {
  INTERNAL: "Internal",
  DOVK: "DOVK",
  RHSK: "RHSK",
  SKPDH: "SKPDH",
  HSK: "HSK",
  EWD: "EWD",
  SKMSLO: "SKMSLO",
  SKDU: "SKDU",
  SKHL: "SKHL",
  SKSP: "SKSP",
  HASPA: "HASPA",
  WVB: "WVB",
};

module.exports = CLIENT_LIST;
