import { useRef, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../auths/hooks/useAuth";
import axios from "../api/axios";

const ROLES_LIST = require("../auths/roles_list");
const CLIENT_LIST = require("../../utilities/client_list");
const CLIENT_TYP = require("../../utilities/client_type_list");

const LOGIN_ENDPOINT = "/auth";
const CONSULTANT_URL = "/consultant";
const ABGABE_URL = "/abgabe";
const ZUORDNUNG_URL = "/zuordnen";
const EINHEIT_BUILDUNG = "/einheitbildung";
const SEGMENT_UEBERPRUEFUNG_URL = "/segment";
const STATS_URL = "/stats";
const EINHEITSICHTEN = "/einheitsichten";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const [consultant_id, setConsultant_id] = useState("");
  const [password, setPassword] = useState("");

  const [remember, setRemember] = useState(false);
  const validate = useMemo(() => {}, []);
  const [showPassword, setShowPassword] = useState(false);

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const authenticate = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(LOGIN_ENDPOINT, JSON.stringify({ consultant_id, password }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      const accessToken = response.data.accessToken;
      const role = response.data.role;
      const competences = response.data.competence_scope;
      const firstLogin = response.data.access_status === "initial";

      setAuth({ consultant_id, password, role, accessToken, competences, firstLogin });

      setPassword(""); //never store  password

      let nextpage;
      if (firstLogin) {
        nextpage = CONSULTANT_URL;
      } else if (role === ROLES_LIST.Admin || role === ROLES_LIST.Auditor) {
        nextpage = STATS_URL;
      } else {
        switch (process.env.REACT_APP_CLIENT) {
          case CLIENT_LIST.HSK:
          case CLIENT_LIST.SKPDH:
          case CLIENT_LIST.SKMSLO:
            nextpage = ABGABE_URL;
            break;
          case CLIENT_LIST.SKHL:
            nextpage = ZUORDNUNG_URL;
            break;
          case CLIENT_LIST.SKSP:
            nextpage = STATS_URL;
            break;
          case CLIENT_LIST.DOVK:
            nextpage = SEGMENT_UEBERPRUEFUNG_URL;
            break;
          case CLIENT_LIST.HASPA:
            nextpage = ZUORDNUNG_URL;
            break;
          default:
            nextpage = process.env.REACT_APP_TYP === CLIENT_TYP.VB_FK ? EINHEIT_BUILDUNG : EINHEITSICHTEN;
        }
      }

      console.log("navigate to : " + nextpage);
      navigate(nextpage);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Server ist nicht erreichbar.");
      } else if (err.response?.status === 400) {
        setErrMsg("Personalnummer oder Passwort fehlt.");
      } else if (err.response?.status === 401) {
        setErrMsg("Personalnummer oder Passwort stimmen nicht überein");
      } else if (err.response?.status === 429) {
        setErrMsg('Konto gesperrt wegen zu vielen falschen Versuchen. Klicken sie "Passwort vergessen?" um passwort zurückzusetzen.');
      } else {
        const resp = err.response;
        console.log({ resp });
        setErrMsg("Login fehlgeschlagen aus unbekanntem Grund");
      }
      errRef.current?.focus();
      //console.log(errMsg);
    }
  };

  const togglePassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  return (
    <>
      <div className="row g-0 auth-wrapper">
        <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
          <div className="d-flex flex-column align-content-end">
            <div className="auth-body mx-auto">
              <p>Einloggen</p>
              <div className="auth-form-container text-start">
                <form className="auth-form" method="POST" onSubmit={authenticate} autoComplete={"off"}>
                  <div className="consultant_id mb-3">
                    <input
                      type="consultant_id"
                      className={`form-control ${validate?.validate?.consultant_id ? "is-invalid " : ""}`}
                      id="consultant_id"
                      name="consultant_id"
                      value={consultant_id}
                      placeholder="Personalnummer"
                      onChange={(e) => setConsultant_id(e.target.value)}
                    />

                    <div className={`invalid-feedback text-start ${validate?.validate?.consultant_id ? "d-block" : "d-none"}`}>
                      {validate?.validate?.consultant_id ? validate.validate.consultant_id[0] : ""}
                    </div>
                  </div>

                  <div className="password mb-3">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${validate?.validate?.password ? "is-invalid " : ""}`}
                        name="password"
                        id="password"
                        value={password}
                        placeholder="Passwort"
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => togglePassword()}>
                        <i className={showPassword ? "far fa-eye" : "far fa-eye-slash"}></i>{" "}
                      </button>

                      <div className={`invalid-feedback text-start ${validate?.validate?.password ? "d-block" : "d-none"}`}>
                        {validate?.validate?.password ? validate.validate.password[0] : ""}
                      </div>
                    </div>

                    <div className="extra mt-3 row justify-content-between">
                      <div className="col-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="remember"
                            checked={remember}
                            onChange={(e) => setRemember(e.currentTarget.checked)}
                          />
                          <label className="form-check-label" htmlFor="remember">
                            Login speichern
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="forgot-password text-end">
                          <Link to="/forgot-password">Passwort vergessen?</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary w-100 theme-btn mx-auto">
                      Einloggen
                    </button>
                  </div>
                </form>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                  {errMsg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"version-wrapper"}>@VERSION@</div>
    </>
  );
};

export default Login;
